function showCards(evt, numCardsContainer) {
  var  tabcontent,totalTabs,totalContentTabs;

  totalTabs = document.getElementsByClassName("faq-nav-tab");
  for (i = 0; i < totalTabs.length; i++) {
    totalTabs[i].classList.remove('active');
  }
  totalTabs[numCardsContainer].classList.add('active');

  totalContentTabs = document.getElementsByClassName("cards-container");
  for (i = 0; i < totalContentTabs.length; i++) {
    totalContentTabs[i].style.display = "none";
  }
  tabcontent = document.getElementsByClassName("cards-container-"+numCardsContainer);

  tabcontent[0].style.display = "block";
}

function openCard(evt, numCard) {
  // Declare all variables
  var  numCard,cardContent,cardHeader;
  cardHeader = document.getElementsByClassName("card-header-"+numCard);

  cardContent = document.getElementsByClassName("body-card-"+numCard);


  if(cardContent[0].classList.contains('open')){
    cardHeader[0].classList.remove('open');
    cardContent[0].classList.remove('open');

  }
  else{
    cardHeader[0].classList.add('open');
    cardContent[0].classList.add('open');

  }
}